.App {
  text-align: center;
}

.App-logo {
  height: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 920px) {
  .about-text {
    font-size: 14px !important;
    width: 75% !important;
  }
  .about-image {
    height: 500px !important;
    background-position: 0px !important;
  }
}

@media (max-width: 700px) {
  .about-text {
    font-size: 14px !important;
    width: 75% !important;
  }

}
@media (max-width: 500px) {
  .about-text {
    font-size: 13px !important;
    width: 75% !important;
  }
}

@media (max-width: 650px) {
  .about-section-main {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 651px) {
  .about-section-main {
    flex-direction: row !important;
  }
}
@media (max-width: 650px) {
  .about-section-image {
    width: 100% !important;
  }
}
@media (max-width: 800px) {
  .about-section-image {
    min-height: 400px !important;
  }
}
@media (max-width: 650px) {
  .about-section-text {
    font-size: 1.5em !important;
    text-align: left !important;
    padding-right: 15px !important;
    justify-content: space-between !important;
    flex-direction: row !important;
  }
}
@media (max-width: 450px) {
  .about-section-text {
    font-size: 1em !important;
  }
}
@media (min-width: 651px) {
  .about-section-text {
    flex-direction: column !important;
  }
}

.about-section-text a {
  color: #2490eb !important;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.overlay-list {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-weight: bold;
  color: white;
}

@media (max-width: 750px) {
  .overlay-list {
    font-size: 1rem;
  }
}

.about-text {
  color: white;
  font-size: 18px;
  padding: 20px;
  width: 50%;
}

#work-request-button-e952dac4-323f-4200-bff6-b4886ec7b867, .modal-trigger-button  {
  display: inline-flex;
  color:#ffffff;
  background-color:#2490eb;
  align-items:center;
  justify-content:center;
  min-height:36px;
  padding:8px 12px;
  font-family:'Noto Sans',Helvetica,Arial,sans-serif;
  font-size:14px;
  font-weight:700;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -webkit-appearance:none;
  text-align:center;
  text-decoration:none;
  border:1px solid transparent;
  border-radius:3px;
  outline:none;
  cursor:pointer;
  vertical-align:middle;
  margin-bottom: 2px;
}

.modal-trigger-button {
  display: inline-flex;
  color:#2490eb;
  background-color:#ffffff;
  align-items:center;
  justify-content:center;
  min-height:36px;
  padding:8px 12px;
  font-family:'Noto Sans',Helvetica,Arial,sans-serif;
  font-size:14px;
  font-weight:700;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -webkit-appearance:none;
  text-align:center;
  text-decoration:none;
  border:1px solid transparent;
  border-radius:3px;
  outline:none;
  cursor:pointer;
  vertical-align:middle;
  margin-bottom: 2px;
}

.emailButton {
  display: inline-flex;
  color:#ffffff;
  background-color:#2490eb;
  align-items:center;
  justify-content:center;
  min-height:36px;
  padding:8px 12px;
  font-family:'Noto Sans',Helvetica,Arial,sans-serif;
  font-size:14px;
  font-weight:700;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -webkit-appearance:none;
  text-align:center;
  text-decoration:none;
  border:1px solid transparent;
  border-radius:3px;
  outline:none;
  cursor:pointer;
  vertical-align:middle;
}

.emailButton:hover {
  text-decoration: none;
  color: white;
}

.emailNav {
  color:black;
}

.emailNav:hover {
  text-decoration: none;
  color: black;
}

.phone {
  color: black;
}
.phone:hover {
  color: black;
  text-decoration: none;
}

.nav-links-mobile {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}
.nav-links {
  display: flex;
  font-size: 1.1rem;
}
@media screen and (max-width: 769px) {
  .nav-links {
    font-size: 0.9rem !important;
  }
}
@media screen and (max-width: 700px) {
  .nav-links {
    display: none !important;
  }
}
@media (min-width: 700px) {
  .dropdown-nav {
    display: none !important;
  }
}
@media (min-width: 701px) {
  button#react-burger-menu-btn,
  .bm-burger-button,
  .bm-burger-bars,
  .bm-burger-bars-hover {
    display: none !important;
  }
}
.stars {
  font-size: 2.2rem;
}
@media (max-width: 450px) {
  .stars {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .stars {
    font-size: 16px;
  }
  .review-text {
    font-size: 18px;
  }
}
@media (min-width: 651px) {
  .review-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 550px) {
  .review-text {
    font-size: 12px;
  }
}

.nav-link:hover {
  color: #2490eb !important;
}
.nav-link {
  color: #043262 !important;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #043262;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2490eb;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  border-left: 2px solid #043262;
  border-top: 2px solid #043262;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

#react-burger-menu-btn:focus {
  outline: none;
}

.bm-item:focus {
  outline: none;
}

.review-button:hover {
  cursor: pointer;
}

@media(max-width: 450px) {
  .nav-login-button  {
    display: none !important;
  }
  .emailNav, .phone {
    font-size: 14px !important;
  }
  .nav-contact {
    margin-bottom: 10px;
  }
}

.hero-buttons {
  display: flex;
}

a.full-body {
  background-image: url("./img/redirect-banner.png");
  background-size: 95%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: block;
}
